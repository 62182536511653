<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="rolePage" :fields="fields" :search-model="searchModel"
                :show-selection="true" :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="name">
          <el-input v-model="searchModel.name" placeholder="角色名称"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleAdd" size="mini" type="primary">新增角色</el-button>
        <el-button @click="handleDelete" size="mini" type="primary">删除</el-button>
      </template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {rolePage, roleSetDefaultDeveloper, roleSwitchClose, deleteRoles} from "@/api/common"

export default {
  name: "Roles",
  data() {
    return {
      props: {
        label: "name",
        children: "child",
      },
      searchModel: {
        name: '',
      },
      rolePage: rolePage,
      fields: [
        {label: '角色名称', key: 'name',width: 200,align: 'left'},
        {label: '角色描述', key: 'describe', align: 'left'},
        {
          label: '账号状态', width: 130,
          render: ({close}, h) => {
            if (!close) {
              return h('span', {attrs: {class: 'color-blue'}}, '已启用')
            } else {
              return h('span', {attrs: {class: 'color-red'}}, '已禁用')
            }
          }
        },
        {label: '创建时间', key: 'time', width: 250},
      ],
      actions: [
        {action: 'info', label: '详情', type: 'primary'},
        {
          action: 'close', label: '禁用', type: 'primary',
          showAction(item) {
            return !item.close
          }
        },
        {
          action: 'close', label: '启用', type: 'primary',
          showAction(item) {
            return item.close
          },
        },
        // {
        //   action: 'setDeveloper',
        //   label: '设置开发商默认角色',
        //   type: 'primary',
        //   showAction(item) {
        //     return !item.isDevelopersDefault
        //   },
        // },
      ],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      this.$router.push({path: '/admin/role-add'})
    },
    // 批量删除角色
    handleDelete() {
      let that = this
      that.$confirm('确认删除角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        let selected = that.$refs.table.selection
        for (let item of selected) {
          ids.push(item.id)
        }
        deleteRoles(ids.join(',')).then(() => {
          that.$message.success('操作成功');
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    infoAction(item) {
      this.$router.push({path: '/admin/role-info/' + item.id})
    },
    closeAction(item) {
      let that = this
      that.$confirm(!item.close ? '禁用后,所有关联该角色的用户将失去其权限？' : '您确认启用？', !item.close ? '确认禁用角色?' : '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        roleSwitchClose(item.id).then(() => {
          that.$message.success('操作成功');
          this.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    setDeveloperAction(item) {
      let that = this
      roleSetDefaultDeveloper(item.id).then(() => {
        that.$message.success('操作成功');
        this.$refs.table.loadData()
      }).catch(() => {
        that.$message.error('操作失败')
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
